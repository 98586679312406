import React from "react"
import { graphql } from "gatsby"
import { capitalize } from "lodash"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ArticlePreview } from "../components/article"

const Category = ({ data, pageContext }) => {
  const posts = data.allFile.edges.map(edge => edge.node.childMarkdownRemark)

  const category = pageContext.slug

  return (
    <Layout>
      <SEO title="Blog" />
      <h1 className="mt-12 -mb-4 w-full px-4 lg:px-8 text-2xl sm:text-3xl font-medium">
        <span className="border-b-4 border-red sm:ml-6">
          {capitalize(category)}
        </span>
      </h1>
      <div className="flex flex-wrap w-full lg:px-8">
        {posts.map((post, i) => {
          return (
            <ArticlePreview
              to={post.fields.slug}
              key={i}
              title={post.frontmatter.title}
              img={
                post.frontmatter.banner &&
                post.frontmatter.banner.childImageSharp.fluid
              }
              category={post.frontmatter.category}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CategoryQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allFile(
      filter: {
        sourceInstanceName: { eq: "blog" }
        extension: { eq: "md" }
        childMarkdownRemark: { frontmatter: { category: { eq: $slug } } }
      }
      sort: { fields: childMarkdownRemark___frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              category
              date(formatString: "MMMM DD, YYYY")
              title
              banner {
                childImageSharp {
                  fluid(maxWidth: 640, maxHeight: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  }
`

export default Category
